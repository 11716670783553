import * as React from "react";

import STRINGS from "../../constants/About-us";

import PageLayout from "../../components/PageLayout";
import Header from "../../components/Header";
import TermsTitleSection from "../../sections/AboutUs/TermsTitleSection/index";
import ContentText from "../../components/ContentText";
import Footer from "../../components/Footer";

const AboutUs = () => {
  return (
    <>
      <Header />
      <PageLayout title="About us">
        <TermsTitleSection title="About us" />
        <ContentText content={STRINGS} />
        <Footer />
      </PageLayout>
    </>
  );
};

export default AboutUs;
